/*
 * @Author: LAPTOP-FRJGB3M2\10279 1398037353@qq.com
 * @Date: 2023-10-13 15:27:51
 * @LastEditors: 邵澳 1398037353@qq.com
 * @LastEditTime: 2023-10-31 14:17:20
 * @FilePath: \cloud_campus_Front-end\school_end\src\xkUI\pages\DatePicker\src\picker\date-picker.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Picker from '../picker.vue';
import DatePanel from '../panel/date.vue';

const getPanel = function(type) {
  DatePanel.components.TimePicker.props.options.default = () => {
    return this.options
  }
  return DatePanel;
};

export default {
  mixins: [Picker],

  name: 'ElDatePicker',

  props: {
    type: {
      type: String,
      default: 'date'
    },
    timeArrowControl: Boolean,
    options:{
      type: Object,
      default: () => {
        return {}
      }
    }
  },

  watch: {
    type(type) {
      if (this.picker) {
        this.unmountPicker();
        this.panel = getPanel(type);
        this.mountPicker();
      } else {
        this.panel = getPanel(type);
      }
    }
  },

  created() {
    this.panel = getPanel(this.type);
  }
};
